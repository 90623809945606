
.export-btn[data-v-e4e3bd2e] {
    color: #ffffff;
    background-color: #409eff;
    border: none;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    line-height: 1;
}
.pagination[data-v-e4e3bd2e] {
    display: flex;
    justify-content: space-between;
}
.gap[data-v-e4e3bd2e] {
    margin-right: 20px;
    margin-bottom: 20px;
}
.handle-box[data-v-e4e3bd2e] {
    margin-bottom: 20px;
}
.handle-select[data-v-e4e3bd2e] {
    width: 120px;
}
.handle-input[data-v-e4e3bd2e] {
    width: 130px;
    display: inline-block;
}
.table[data-v-e4e3bd2e] {
    width: 100%;
    font-size: 14px;
}
.red[data-v-e4e3bd2e] {
    color: #ff0000;
}
.mr10[data-v-e4e3bd2e] {
    margin-right: 10px;
}
.table-td-thumb[data-v-e4e3bd2e] {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.show[data-v-e4e3bd2e] {
    display: block;
}
.hide[data-v-e4e3bd2e] {
    display: none;
}
.checkbox-rows[data-v-e4e3bd2e] {
    width: 130px;
    margin-top: 10px;
}
.el-checkbox.is-bordered + .el-checkbox.is-bordered[data-v-e4e3bd2e] {
    margin-left: 0px;
}
